export const red500 = "#F44336";
export const redA700 = "#D50000";
export const pink500 = "#E91E63";
export const pinkA400 = "#F50057";
export const purple500 = "#9C27B0";
export const purpleA700 = "#AA00FF";
export const deepPurple500 = "#673AB7";
export const deepPurpleA400 = "#651FFF";
export const indigo500 = "#3F51B5";
export const indigoA700 = "#304FFE";
export const blue500 = "#2196F3";
export const blueA700 = "#2962FF";
export const lightBlue500 = "#03A9F4";
export const lightBlueA700 = "#0091EA";
export const cyan500 = "#00BCD4";
export const cyanA700 = "#00B8D4";
export const teal200 = "#03DAC5";
export const teal500 = "#009688";
export const tealA700 = "#00BFA5";
export const green500 = "#4CAF50";
export const greenA700 = "#00C853";
export const lightGreen500 = "#8BC34A";
export const lightGreenA700 = "#64DD17";
export const lime500 = "#CDDC39";
export const limeA700 = "#AEEA00";
export const yellow500 = "#FFEB3B";
export const yellowA700 = "#FFD600";
export const amber500 = "#FFC107";
export const amberA700 = "#FFAB00";
export const orange500 = "#FF9800";
export const orangeA700 = "#FF6D00";
export const deepOrange500 = "#FF5722";
export const deepOrangeA700 = "#DD2C00";
export const blueGray50 = "#ECEFF1";
export const blueGray500 = "#607D8B";
